<script>
import moment from 'moment';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from 'vue-multiselect'
import 'vue2-datepicker/locale/pt-br';
import ProductService from '@/services/product'
import { VMoney } from 'v-money'

moment.locale('pt-BR');

export default {
  page: {
    title: "Desconto por Quantidade (Mobwit)",
  },
  components: { Layout, PageHeader, Multiselect },
  directives: { money: VMoney },
  data() {
    return {
      title: "Desconto por Quantidade (Mobwit)",
      loading: false,
      productQuantityDiscounts: [],
      productQuantityDiscount: {},
      products: [],
      departments: [],
      modalError: '',
      productQuantityDiscountToRemove: '',
      isLoading: false,

      searchValue: '',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      fields: [
        { key: 'referenceDate', sortable: true, label: 'Data Ref.', formatter: (value) => {
          return moment(value).format('DD/MM/YYYY');
        } },
        { key: 'productDescription', sortable: true, label: 'Produto/Departamento', formatter: (value, key, item) => {
          return item.productDescription || item.departmentDescription
        } },
        { key: 'companyDescription', sortable: true, label: 'Empresa' },
        { key: 'quantity', sortable: true, label: 'Quantidade' },
        { key: 'stock1', sortable: true, label: 'Estoque MATRIZ' },
        { key: 'stock3', sortable: true, label: 'Estoque FILIAL' },
        { key: 'discount', sortable: true, label: 'Desconto', class: 'nowrap-col', formatter: (value) => {
          return `${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`
        } },
        { key: 'invoiceQuantity', sortable: true, label: 'Qtde. Vendida' },
        { key: 'actions', label: 'Ações', class: 'nowrap-col' }
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load(showLoading = true, page = 1, term = '') {
      let loader;

      if (showLoading) {
        loader = this.$loading.show();
      }

      try {
        const productQuantityDiscountResult = await ProductService.getProductQuantityDiscountMobwit(term, page);
        this.productQuantityDiscounts = productQuantityDiscountResult.data.productQuantityDiscounts;

        const departmentsResult = await ProductService.getDepartments();
        this.departments = departmentsResult.data;

        this.count = productQuantityDiscountResult.data.count;
        this.currentCount = productQuantityDiscountResult.data.currentCount;
        this.currentPage = productQuantityDiscountResult.data.currentPage;
        this.totalPages = productQuantityDiscountResult.data.totalPages;
        this.perPage = productQuantityDiscountResult.data.perPage;
        this.startIndex = productQuantityDiscountResult.data.startIndex;
        this.endIndex = productQuantityDiscountResult.data.endIndex;

        if (showLoading) {
          loader.hide();
        }
      } catch (err) {
        if (showLoading) {
          loader.hide();
        }
        this.$toast.error('Ocorreu um erro ao carregar as configurações');
      }
    },
    async saveProductQuantityDiscount() {
      this.modalError = '';

      if (!this.productQuantityDiscount._id && !this.productQuantityDiscount.product && !this.productQuantityDiscount.department) {
        return this.modalError = 'Informe o produto ou o departamento';
      }

      const data = {
        ...this.productQuantityDiscount,
        quantity: Number(this.productQuantityDiscount.quantity.toString().replace('.', '').replace(',', '.')),
        discount: Number(this.productQuantityDiscount.discount.toString().replace('.', '').replace(',', '.')),
        origin: 'mobwit',
      }

      if (!this.productQuantityDiscount._id && this.productQuantityDiscount.product) {
        data.productCode = this.productQuantityDiscount.product.code;
      }

      if (!this.productQuantityDiscount._id && this.productQuantityDiscount.department) {
        data.departmentCode = this.productQuantityDiscount.department.code;
      }

      if (!data.quantity) {
        return this.modalError = 'Informe a quantidade do produto';
      }

       if (!data.discount) {
        return this.modalError = 'Informe o desconto do produto';
      }

      let loader = this.$loading.show();

      try {
        if (this.productQuantityDiscount._id) {
          await ProductService.updateProductQuantityDiscount(data);
        } else {
          await ProductService.createProductQuantityDiscount(data);
        }

        loader.hide();

        this.load(false);

        this.$bvModal.hide('modal-edit');
        this.$toast.open('Dados atualizados com sucesso');
      } catch (err) {
        loader.hide();
        
        if (err && err.response.data.code && err.response.data.code === 'E9999') {
          return this.$toast.warning(err.response.data.msg);
        }

        this.$toast.error('Ocorreu um erro inesperado');
      }
    },
    onPageChange() {
      window.scrollTo(0,0);
      this.load(true, this.currentPage);
    },
    search() {
      this.searchEmptyField = false,
      this.load(true, 1, this.searchValue);
    },
    changeSearch() {
      if (this.searchValue === '' && !this.searchEmptyField) {
        this.searchEmptyField = true;
        this.load(true, 1, this.searchValue);
      }
    },
    newProductQuantityDiscount() {
      this.productQuantityDiscount = {
        company: '0'
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    editProductQuantityDiscount(item) {
      this.productQuantityDiscount = {
        _id: item._id,
        productDescription: item.productDescription,
        departmentDescription: item.departmentDescription,
        quantity: item.quantity.toString().replace('.', ','),
        discount: item.discount.toFixed(2).replace('.', ','),
        company: item.company,
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    removeProductQuantityDiscount() {
      this.$bvModal.hide('modal-remove');

      ProductService.removeProductQuantityDiscount(this.productQuantityDiscountToRemove).then(() => {
        this.load(false);
        this.$toast.open('Desconto removido com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao remover o desconto');
      })
    },
    findProduct(term) {
      this.isLoading = true;

      ProductService.getUniqueProducts(term).then(res => {
        this.isLoading = false;

        this.products = res.data;
      }).catch(() => {
        this.isLoading = false;
      })
    },
    exportData() {
      this.loading = true;

      ProductService.exportProductQuantityDiscountMobwit(this.searchValue).then(res => {
        this.loading = false;
        
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'ProdutosDescontoPorQuantidade.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch(() => {
        this.loading = false;
      })
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2 align-items-center">
              <div class="col-sm-8 col-lg-6">
                <div class="mr-2 mb-2">
                  <div class="position-relative search-input">
                    <form @submit.prevent="search" class="align-items-center">
                      <input
                        type="text"
                        class="form-control"
                        v-model="searchValue"
                        v-on:keyup="changeSearch"
                        placeholder="Pesquisar por nome do produto"
                      />

                      <button type="submit" class="btn btn-primary" v-if="!loading">
                        Pesquisar
                      </button>

                      <b-button class="ml-2" variant="outline-primary" @click="exportData" v-if="!loading">Exportar</b-button>

                      <a href="javascript:void(0);" class="text-primary" v-if="loading">
                        <b-spinner class="ml-2" variant="primary" role="status"></b-spinner>
                      </a>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-sm-4 col-lg-6">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="newProductQuantityDiscount()">
                    <i class="mdi mdi-plus mr-1"></i> Novo Desconto  
                  </button>
                </div>
              </div>
            </div>  

            <div>
             
              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="productQuantityDiscounts"
                :fields="fields"
                responsive="sm"
                head-variant="light"
              >
                <template v-slot:cell(actions)="row">
                 

                  <a href="javascript:void(0);" class="action-icon text-success mr-3" @click="editProductQuantityDiscount(row.item)">
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a href="javascript:void(0);" class="action-icon text-danger" v-b-modal.modal-remove @click="productQuantityDiscountToRemove = row.item._id" >
                    <i class="mdi mdi-close-circle-outline font-size-18"></i>
                  </a>
                </template>
              </b-table>

              <div class="row justify-content-md-between align-items-md-center mt-4">
                <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>
                <!-- end col-->
                <div class="col-xl-5">
                  <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                    <b-pagination
                      v-model="currentPage" 
                      :total-rows="count"
                      :per-page="perPage"
                      @input="onPageChange"
                    ></b-pagination>
                  </div>
                </div>
                <!-- end col-->
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <b-modal id="modal-edit" :title="productQuantityDiscount._id ? 'Alterando Desconto' : 'Novo Desconto'" centered>
      <b-alert v-if="modalError" v-model="modalError" variant="danger" dismissible>{{modalError}}</b-alert>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="control-label">Produto</label>
            
            <input
              id="productDescription"
              name="productDescription"
              type="text"
              class="form-control"
              :disabled="true"
              v-if="productQuantityDiscount._id"
              :value="productQuantityDiscount.productDescription"
            />

            <multiselect
              v-model="productQuantityDiscount.product"
              :options="products"
              :close-on-select="true"
              :clear-on-select="true"
              :loading="isLoading"
              @search-change="findProduct"
              placeholder="Selecione o produto por nome ou código"
              label="fullDescription"
              track-by="code"
              selectLabel="Enter para selecionar"
              selectedLabel="Selecionado"
              deselectLabel="Enter para remover"
              v-if="!productQuantityDiscount._id"
              :disabled="productQuantityDiscount.department"
            ></multiselect>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group">
            <label class="control-label">Departamento</label>

            <input
              id="departmentDescription"
              name="departmentDescription"
              type="text"
              class="form-control"
              :disabled="true"
              v-if="productQuantityDiscount._id"
              :value="productQuantityDiscount.departmentDescription"
            />

            <multiselect
              v-model="productQuantityDiscount.department"
              :options="departments"
              placeholder="Selecione o departamento"
              label="description"
              track-by="_id"
              selectLabel="Enter para selecionar"
              selectedLabel="Selecionado"
              deselectLabel="Enter para remover"
              v-if="!productQuantityDiscount._id"
              :disabled="productQuantityDiscount.product"
            ></multiselect>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label for="quantity">Quantidade</label>

            <input
              id="quantity"
              name="quantity"
              type="tel"
              class="form-control"
              v-model="productQuantityDiscount.quantity"
            />
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label for="discount">% Desconto</label>

            <input
              id="discount"
              name="discount"
              type="tel"
              class="form-control"
              v-mask="'##,##'"
              v-money="money"
              v-model="productQuantityDiscount.discount"
            />
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label>Empresa</label>
            <select
              v-model="productQuantityDiscount.company"
              class="form-control"
              name="activityType"
            >
              <option value="0">TODAS</option>
              <option value="1">MATRIZ</option>
              <option value="3">ITAJAÍ</option>
            </select>
          </div>
        </div>
      </div>
      
      <template v-slot:modal-footer>
        <b-button variant="link" @click="$bvModal.hide('modal-edit')">Cancelar</b-button>
        <b-button variant="primary" @click="saveProductQuantityDiscount()">
          Gravar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-remove" title="Excluir Desconto" title-class="font-18" centered>
      <p>Confirma a exclusão do desconto?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove')">Cancelar</b-btn>
        <b-btn variant="primary" @click="removeProductQuantityDiscount">Excluir</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .search-input form {
    display: flex;
    flex-direction: row;

    .btn {
      margin-left: 10px;
    }
  }

  .form-control:disabled {
    background-color: #e6e6e6;
  }
</style>